import React from "react"
import "./ppi.css"
import Footer from "../../components/footer"
import WorkHeader from "../../components/workHeader"
import ppi from "../../../static/work_headers/ppi.png"
import Dict from "../../langs/dict"
import WorkAbout from "../../components/workAbout"
import Perk from "../../components/perks"
import ppi1 from "../../../static/works_perks/ppi_1.png"
import ppi3 from "../../../static/works_perks/ppi_2.png"
import ppi2 from "../../../static/works_perks/ppi_3.png"
import Contact from "../../components/contact"
import logo from "../../../static/logos/ppi_logo.png"
import Layout from "../../components/layoutWorks"

export default () => {
  const lang = "en"
  const dict = new Dict(lang)
  return (
    <Layout
      seoTitle="Website - PPI"
      seoDescription="PPI, a leading Swiss firm in the Foreign Currency market approached us to develop their new online image by creating a modern website."
    >
      <div id="works-ppi-background">
        <WorkHeader
          title={dict.getTrans("work_ppi_title")}
          subTitle={dict.getTrans("work_ppi_subtitle")}
          pic={ppi}
          navLang={lang}
          hl="#F38141"
          pageUrl="/works/ppi"
        />
      </div>
      <WorkAbout
        title={dict.getTrans("ppi_about_title")}
        p1={dict.getTrans("ppi_about_p1")}
        p2={dict.getTrans("ppi_about_p2")}
        logo={logo}
      />
      <div id="works-ppi-perks-holder">
        <Perk
          title={dict.getTrans("ppi_perk1_title")}
          image={ppi1}
          text={dict.getTrans("ppi_perk1_text")}
        />
        <Perk
          title={dict.getTrans("ppi_perk2_title")}
          image={ppi2}
          text={dict.getTrans("ppi_perk2_text")}
        />
        <Perk
          title={dict.getTrans("ppi_perk3_title")}
          image={ppi3}
          text={dict.getTrans("ppi_perk3_text")}
        />
      </div>
      <div id="works-tft-contact">
        <Contact lang="en" />
      </div>
      <Footer lang={lang} />
    </Layout>
  )
}
